import React from 'react'
import Layout from '../../templates/Restorer'
import Feed from '../../modules/Feed'
import { Box } from 'rebass/styled-components'

const RestorerWelcomePage = () => {
    return (
        <Layout>
            <Feed type='clientCurrRest' />
            <Box mb='xl'>
                <Feed type='clientPrevRest'/>
            </Box>
        </Layout>
    )
}

export default RestorerWelcomePage
