import React from 'react'
import { Box } from 'rebass/styled-components'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import Meta from '../components/general/Meta'
import { connect } from 'react-redux'

const RestorerLayout = ({ children }) => {
    return (
        <>
            <Meta />
            <Box>
                <Header type='main' />
                <Box sx={{ minHeight: 'calc(100vh - 275px)' }}>{children}</Box>
                <Footer />
            </Box>
        </>
    )
}

function mapStateToProps(state) {
    return {
        vid: state.Vehicle.vid,
        role: state.Auth.user.role,
    }
}
export default connect(mapStateToProps)(RestorerLayout)
